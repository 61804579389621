// |------------------------------------------------------
// |------------------------------------------------------
// | Dashboard
// |------------------------------------------------------
// |------------------------------------------------------

.carousel-list
	background: $white
	border-radius: $radius
	padding: 10px
	&.has-shadow
		box-shadow: none!important

.dashboard
	position: relative
	min-height: calc(100vh - 130px)
	.is-size-5
		letter-spacing: -0.03em
	.carousel-slide
		border: 0!important
	// .carousel-slides
	// 	gap: 20px
	&__title
		color: $grey-dark
		line-height: 1.1
		text-align: center
		letter-spacing: -0.04em
	&__select
		position: relative
		display: inline-block
		margin-bottom: 5px
		.icon
			position: absolute
			top: 12px
			right: 11px
			opacity: 0.4
		&:hover
			.icon
				opacity: 1
		select
			cursor: pointer
			background: $white
			border: 1px solid $grey-lighter
			&:hover
				//background: $grey-lightest
				color: $black
				border-color: $grey-light
			option
				text-align: left
				padding: 0
				font:
					family: $font-light
					size: 18px
		select,
		&__inner
			max-width: 100%
			text-align: center
			color: $grey-dark
			border-radius: 5px
			padding: 8px 40px 7px
			appearance: none
			-webkit-appearance: none
			font:
				family: $font-medium
				size: 18px
			transition: all 0.1s ease-out
			@include touch
				font-size: 14px

	&__image
		background-repeat: no-repeat
		background-position: center center
		background-size: cover
		height: calc(100% - 18px)
		margin: 17px 0 0 0
		border-radius: 6px
		@include touch
			display: none
		@for $i from 1 through 5
			&--#{$i}
				background-image: url('./assets/images/dashboard-#{$i}.jpg')

	&__box
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		// border-radius: 5px
		font-size: 3rem
		max-width: none
		// background: $grey-lightest
		margin: 0
		border-right: 3px solid $grey-lightest
		&.criterios
			border-right: 0
		@include desktop
			// max-width: 180px
		&__value
			display: flex
			justify-content: center
			align-items: center
			color: $secondary
			font:
				family: $font-medium
			letter-spacing: -0.06em
			small
				// color: $grey-light
				font:
					size: 55%
					family: $font-light
				position: relative
				top: 4px
				left: -1px
		&__type
			display: block
			margin-top: -10px
			font:
				size: 1rem
				family: $font-regular
	&__panel
		background: $white
		padding: spacing()
		border-radius: 5px
		// border: 1px solid $grey-lighter
		border-bottom: 2px solid $grey-lighter
	&__list
		min-height: 345px
		margin-top: -13px
		padding: spacing() 0 spacing(2)
		&__item
			display: flex
			font-size: 1.1em
			padding: 2px spacing()
			@include mobile
				padding: spacing(2.5) spacing()
			> span
				position: relative
				top: 1px
				display: flex
				align-items: center
			&:nth-child(even)
				background: $white-bis
		&__position
			width: 15%
		&__name
			width: 70%
			font-size: 90%
		&__date
			display: block
			margin-top: -6px
		&__rate
			width: 15%
			.tooltip-trigger
				display: flex
				text-align: right
				justify-content: flex-end
			.rate
				min-width: 35px
			svg
				margin-right: 4px
				fill: #95F3FA
			&.large
				font-size: 130%
				svg
					width: 20px
		&__dealer,
		&__dealers,
		&__dealers a
			display: flex
			justify-content: space-between
			align-items: center
			padding: spacing(3) spacing()
			@include mobile
				padding: spacing(2) spacing()
			> div
				width: 80%
		&__dealers
			padding: 0
			a
				width: 100%
				color: rgba($black, 0.4)
				padding: spacing(3) spacing()
				@include mobile
					padding: spacing(2) spacing()
				&:hover
					opacity: 1
					color: $white
					background: $secondary

	&__error
		height: calc(100% - 90px)!important
	&__results
		display: flex
		justify-content: center
		align-items: center
		text-align: center
	&__results,
	&__error
		svg
			width: 50px!important
			opacity: 0.6
			margin: 0 0 15px 0!important
