// |------------------------------------------------------
// |------------------------------------------------------
// | Page
// |------------------------------------------------------
// |------------------------------------------------------

.page
	padding: $block-spacing
	width: 100%
	min-height: 100vh
	@include desktop
		padding-left: $block-spacing * 2

	&__title
		max-width: 100%
		display: flex
		flex-direction: column
		justify-content: flex-start
		align-items: flex-start

	&__actions
		display: flex
		align-items: center
		justify-content: flex-end
		@include desktop
			min-height: 70px
		@include touch
			padding-top: 0
			padding-bottom: 0
			justify-content: flex-end
		@include mobile
			justify-content: flex-start
			margin-bottom: $block-spacing
			.icon
				display: none

		.b-skeleton
			height: 40px
			&.is-centered
				position: relative
				top: 5px
				width: 100px
				margin-right: 1.5rem
			&.is-right
				width: 165px
				margin-top: 0
			&-item
				border-radius: 30px!important

		.button
			// line-height: 1.95
			.icon
				position: relative
				top: 2px
			.plus,
			.add-user
				top: -1px
				svg
					width: 21px
			> span
				position: relative
				top: -1px

.circle
	display: flex
	align-items: center
	justify-content: center
	border-radius: 100%
	font-size: 1.2rem
	color: $white
	font-family: $font-medium
	background: $grey-light
	&.light
		background: #95F3FA
	&.medium
		background: #4A9FF4
	&.dark
		background: #1248C9

.rate
	&.light
		color: #95F3FA
	&.medium
		color: #4A9FF4
	&.dark
		color: #1248C9
