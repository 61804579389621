// |------------------------------------------------------
// |------------------------------------------------------
// | Helpers
// |------------------------------------------------------
// |------------------------------------------------------

// Reset
// *
// 	margin: 0
// 	padding: 0
// 	box-sizing: border-box

*:focus
	outline: none

body,
html
	background: $white-ter

html,
body,
.scroll,
.modal-card-body

	&::-webkit-scrollbar-track
		background: $white-bis
		// background: linear-gradient(to right, var(--scroll-track-color) 0%, var(--scroll-track-color) 30%, var(--scroll-color) 60%, var(--body-bg-color) 61%, var(--body-bg-color) 100%)

	&::-webkit-scrollbar
		width: 7px

	&::-webkit-scrollbar-thumb
		background-color: $grey-lighter
		border-radius: 0.313rem
		// border: 1px solid white
		// border-radius: $radius

::selection
	background-color: $primary
	color: $white

.no-events
	pointer-events: none

.no-select
	user-select: none

.pointer
	cursor: pointer

.hidden
	display: none
	opacity: 0

.is-ellipsis
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis

.multiselect__option
	white-space: normal!important
	span
		display: inline-block
		padding-right: 90px

.pointer
	cursor: pointer

.no-events
	pointer-events: none

.help
	cursor: help

.rotate-180,
.rotate-180 svg
	transform: rotate(180deg)

.star
	svg
		top: -2px
		position: relative
		width: 15px
		margin-left: 5px

.light
	svg
		fill: #95F3FA
.medium
	svg
		fill: #4A9FF4
.dark
	svg
		fill: #1248C9

// Positions
$positions: relative, absolute, fixed
@each $position in $positions
	.is-#{$position}
		position: $position

$fonts: 'Bold', 'Medium', 'Regular', 'Light', 'SemiLight'
@each $font in $fonts
	@font-face
		font-family: 'Volvo Novum-#{$font}'
		src: url('~@/assets/fonts/Volvo Novum-#{$font}.eot')
		src: url('~@/assets/fonts/Volvo Novum-#{$font}.woff') format('woff'), url('~@/assets/fonts/Volvo Novum-#{$font}.ttf') format('truetype'), url('~@/assets/fonts/Volvo Novum-#{$font}.svg') format('svg')
		font-weight: normal
		font-style: normal

// Font Weight
$families: ('light': $font-light, 'semilight': $font-semilight, 'regular': $font-regular, 'medium': $font-medium, 'bold': $font-bold)
@each $name, $family in $families
	.is-#{$name}
		font-family: $family

// Align
$aligns: center, left, right, justify
@each $align in $aligns
	.text-#{$align}
		text-align: $align

// Text Transform
$transforms: capitalize, uppercase, lowercase, none
@each $transform in $transforms
	.text-#{$transform}
		text-transform: $transform

// Rating Colors
$colors: ('light': #95F3FA, 'medium': #4A9FF4, 'dark': #1248C9)
@each $name, $color in $colors
	.color-#{$name}
		color: $color!important

$backgrounds: ('light': #95F3FA, 'medium': #4A9FF4, 'dark': #1248C9)
@each $name, $background in $backgrounds
	.background-#{$name}
		color: $white!important
		background: $background!important
