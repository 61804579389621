// |------------------------------------------------------
// |------------------------------------------------------
// | Ranking
// |------------------------------------------------------
// |------------------------------------------------------

.ranking
    border: 0
    h3
        font-size: 20px
    .position
        position: absolute
        top: -15px
        font-size: 25px
        left: 30px
        color: $grey-light
    .medal
        position: absolute
        top: 0
        right: 50px
        opacity: 0.4
        svg
            width: 40px
        &.medal-1 svg
            fill: #FFD700
        &.medal-2 svg
            fill: #C0C0C0
        &.medal-3 svg
            fill: #CD7F32
    .b-tooltip
        position: absolute
        right: 20px
        top: 13px
        .tooltip-trigger
            display: flex
            align-items: center
            flex-direction: column
            font-size: 1.4em
            svg 
                top: 0px
                width: 25px