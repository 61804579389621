// |------------------------------------------------------
// |------------------------------------------------------
// | Weather
// |------------------------------------------------------
// |------------------------------------------------------

.weather
	display: flex
	justify-content: flex-end
	align-items: center
	padding: 0 spacing()
	margin: spacing(2.5) spacing(2)
	border-left: 1px solid $grey-lighter
	border-right: 1px solid $grey-lighter
	user-select: none
	width: auto
	max-width: 200px
	min-width: 120px
	min-height: 51px
	white-space: nowrap
	opacity: 0.65
	&.no-border
		border-left-color: transparent
	img
		width: 65px
	&__temperature
		display: flex
		flex-direction: column
		justify-content: flex-start
		width: 100%
		text-transform: uppercase
		color: $primary
		.title
			margin-bottom: 1.3rem
			color: inherit
		.title,
		.subtitle
			color: inherit
