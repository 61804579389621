// |------------------------------------------------------
// |------------------------------------------------------
// | Blocks
// |------------------------------------------------------
// |------------------------------------------------------

@use 'sass:math'

.block
	display: flex
	align-items: center
	justify-content: flex-start
	position: relative
	padding: spacing(2) spacing(1.5)
	background: $white
	min-height: 80px
	border-radius: 3px
	box-shadow: $shadow
	border: 1px solid $white
	border-left: 5px solid $grey-light
	transition: $transition-base
	&.secondary
		border-left-color: $secondary
	&.inactive
		background: rgba($white, 0.15)
		border-left-color: $grey-lighter!important
	&.placeholder
		pointer-events: none
	&:hover
		opacity: 1
		background: $white
		border-left-color: $primary!important
	&.done
		background: rgba($success, 0.08)
		border-color: $success!important
		.icon.checked
			position: absolute
			top: -10px
			right: -10px
			font-size: 40px
			background: $white
			border-radius: 100%
			svg
				fill: $success

	&__content
		padding: 0 spacing(1.5)
		display: flex
		flex-direction: column
		line-height: 1.2
		width: calc(100% - 50px)
		&.contest
			width: 100%

	&__bubble
		display: flex
		justify-content: center
		align-items: center
		position: absolute
		top: 31px
		right: 20px
		.points
			margin-top: -4px
		svg
			width: 20px
			margin-right: 5px

	&__role
		display: block
		text-indent: -9999px
		width: 8px
		height: 8px
		background: $grey-light
		border-radius: 100%
		border: 1px solid $white

	&__avatar
		display: flex
		color: $white
		align-items: center
		justify-content: center
		background: $grey-lighter
		border-radius: 100%
		font:
			size: 1.2rem
			family: $font-medium
		.b-tooltip
			position: absolute
			top: 0
			right: 5px
			z-index: 3

		.image
			width: 100%

	&__contest
		font-family: $font-semilight

	&__name
		color: $grey
		font-family: $font-medium
		&--user
			padding-top: 0.45rem
		.b-skeleton
			margin-top: 0

	&__email
		text-transform: lowercase

	&__email,
	&__address
		color: $grey-light
		font:
			family: $font-light
			size: 0.85em
		&.secondary
			margin-top: 3px

	&__state
		text-transform: uppercase
		font:
			size: 14px
			family: $font-light
		color: $grey-lighter
		user-select: none
		letter-spacing: 0
		padding: spacing(1.5) spacing(1.5) 0 0

	&__status
		margin-right: 25px
		margin-top: 6px
		padding: 0
		svg
			width: 35px

	&__dropdown
		position: absolute
		top: 50%
		right: 15px
		margin: -13px 0 0 0

		.dropdown-content
			width: 100%
			border: 1px solid $white-ter
			.svg
				position: relative
				top: -1px
				margin-right: 3px
				svg
					position: relative
					height: auto
					width: 13px
					// margin-right: 3px
				&.profile,
				&.check
					.fill
						fill: $info
				&.check
					svg
						width: 17px
						left: -3px
				&.profile
					svg
						width: 15px
						left: 0
						top: -1px
				&.criteria
					.fill
						fill: $secondary
				&.money
					svg
						width: 15px
					.fill
						fill: $success
				&.xls
					svg
						width: 10px
						left: 2px
						height: auto
						margin-right: 5px
					.fill
						fill: $success
				&.edit
					svg
						width: 11px
						margin-left: 2px
						margin-right: 2px
				&.export
					top: -2px
					svg
						fill: currentColor
				&.trash
					top: -2px
					svg
						width: 16px
						margin-right: 0
				&.contest
					// top: 3px
					svg
						width: 15px
					.fill
						fill: $warning
				&.restore
					top: 0

		.dropdown-item
			font-family: $font-regular

		.dots
			opacity: 0.3
			border: none
			cursor: pointer
			&:hover
				opacity: 0.5
	&__users
		position: absolute
		right: 26px
		bottom: -23px
		padding: 5px 0
		.b-tooltip
			width: 25px
			margin: 0 -10px 0 0
			img
				max-width: 100%
				border-radius: 100%
				border: 1px solid $white
	&__arrow
		position: absolute
		top: 15px
		right: 15px

.block-button
	background: $white
	display: flex
	justify-content: space-between
	align-items: center
	padding: 6px 35px
	margin: 0 0 20px 0
	transition: $transition-base
	color: $grey-dark
	box-shadow: 0 0 15px rgba($black, 0.05)
	&.placeholder
		height: 78px
	.title
		color: inherit
		.b-skeleton
			margin-top: 20px
			+ .b-skeleton
				margin-top: -5px
		small
			opacity: 0.55
	&:hover,
	&.opened
		background: $secondary
		color: $white
