// |------------------------------------------------------
// |------------------------------------------------------
// | Multiselect
// |------------------------------------------------------
// |------------------------------------------------------

.multiselect__tags
	border-color: #dbdbdb!important
	min-height: 50px!important

.multiselect__select
	&:before
		display: none

.multiselect__single
	padding: 7px 7px 0
	font-family: $font-light

.multiselect__tag
	padding: 10px 26px 10px 10px!important
	border-radius: 4px!important
	background: $secondary!important
	margin-bottom: 0!important

.multiselect__tag-icon
	line-height: 36px!important
	border-radius: 0 4px 4px 0!important
	&:hover
		background: darken($secondary, 5%)!important
	&:after
		color: white!important
		font-weight: normal
