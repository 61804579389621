// |------------------------------------------------------
// |------------------------------------------------------
// | Overrides
// |------------------------------------------------------
// |------------------------------------------------------

body
	letter-spacing: 0.02em

.name
	font:
		size: 19px
		weight: $font-medium
	line-height: 1
	padding-top: 5px
	margin-bottom: 0
	color: $black

.title
	font:
		size: 1.8rem
		weight: normal
		family: $font-medium
	letter-spacing: -0.04em

.button
	&.export
		min-width: 100px
		margin: 2px spacing() 0 0
	&.create
		padding: spacing(2) $block-spacing * 1.275
		> span
			display: flex
			justify-content: center
			align-items: center
			padding-top: 2px
	&.save
		padding: spacing(2) $block-spacing * 2

.button
	border-width: 0.1rem
	font-family: $font-regular
	span
		position: relative
		top: -1px

.svg
	fill: $primary
	transition: $transition-base
	.fill,
	.rect
		fill: $primary
	.stroke
		stroke: $primary
	&__icon
		position: relative
		width: 25px
		height: 100%
		fill: inherit
		vertical-align: middle
		.criteria &
			width: 21px
			top: -1px
			margin-left: 2px

.vue-swatches
	@include tablet
		margin-top: 3px
	&__trigger
		@include mobile
			border-radius: 2px!important
			width: 100%!important
			height: 15px!important

.vue-swatches,
.vue-swatches__trigger__wrapper
	@include mobile
		width: 100%

.control.has-icons-left .icon,
.control.has-icons-right .icon
	height: 100%

.noOpacity
	opacity: 1

.toast
	small
		display: block

strong
	color: inherit
	font:
		weight: normal
		family: $font-medium

.tooltip-content
	font-family: $font-light

.stroke
	stroke: $black

.divider
	border: none
	border-bottom: 1px solid #F5F5F5
	&.dark
		border-bottom-color: $grey-light

.datepicker .dropdown-content
	box-shadow: 0 0 15px rgb(10 10 10 / 20%)!important
	.field .select
		font-size: 14px
		max-height: 40px
		min-width: 80px
	.datepicker-table .datepicker-body .datepicker-cell.is-today
		color: $secondary
		border-color: $white
		font-family: $font-medium

.multiselect__option--highlight,
.multiselect__option--selected
	font-weight: normal!important

.multiselect__option--selected span
	font-family: $font-medium

.multiselect__tag
	white-space: normal!important
	line-height: 1.3

.multiselect__tag-icon
	display: flex
	justify-content: center
	align-items: center
	line-height: 1

.loading-overlay .loading-background
	background: $white-ter

.has-label
	position: relative
	margin-top: 30px

.dropdown-label
	position: absolute
	top: -21px
	right: 0
	left: 0
	text-transform: uppercase
	font:
		size: 10px
		weight: $weight-medium
	color: $grey-light
	border-bottom: 1px solid $grey-lightest
	padding: 0 0 5px 16px

.notices,
.notices .toast
	z-index: 9999!important

.large-text
	font-size: 18px

.btn-billing
	min-height: 150px
	border: 0 !important
	display: flex
	flex-direction: column
	strong
		display: block
	span
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		span
			line-height: 1
	.svg
		width: 40px
		height: 40px
		margin: 0 0 10px 0px
		&.import
			position: relative
			top: 5px
		svg
			width: 100%
			margin: 0 0 0 10px

.btn-policy
	position: absolute
	top: 12px
	right: 0
	.icon
		width: 12px
		margin: 0 10px 0 0
