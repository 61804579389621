// |------------------------------------------------------
// |------------------------------------------------------
// | Breadcrumb
// |------------------------------------------------------
// |------------------------------------------------------

.breadcrumb
	// padding: 5px 0 0 15px
	&.has-dot-separator li + li::before
		font-size: 1.4rem
		height: 15px
		display: flex
		align-items: center
		position: relative
		top: 0
	li
		white-space: normal
		a
			color: $grey-light
			font-size: 0.8rem
			&:hover
				color: $grey

	li.is-active a
		color: $grey-light
		font-family: $font-regular
