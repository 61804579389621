// |------------------------------------------------------
// |------------------------------------------------------
// | Modal
// |------------------------------------------------------
// |------------------------------------------------------

.modal
	z-index: 9999 
	.modal-table
		background: transparent
		margin: 10px 0 20px 0
		border-radius: 10px
		.is-selected
			color: $secondary
			background: white
		th
			font-weight: normal
			font-family: $font-medium
			letter-spacing: 0
		th, td
			border: none
			padding: 10px 20px
			overflow: hidden
		thead,
		tfoot
			th
				text-transform: uppercase
				color: $secondary
				font-family: $font-bold
		tbody
			background: white
			tr:first-child
				th
					border-radius: 10px 0 0 0
				td
					border-radius: 0 10px 0 0
			tr:last-child
				th
					border-radius: 0 0 0 10px
				td
					border-radius: 0 0 10px 0
	.modal-card-head
		padding: 30px 20px
	.modal-card-title
		display: flex
		justify-content: space-between
		color: $primary
		&-button
			position: absolute
			right: 30px
			min-width: 130px
		&.ellipsis
			display: -webkit-box
			-webkit-box-orient: vertical
			-webkit-line-clamp: 3
			overflow: hidden
			width: 100%
		.is-contest
			display: block
			font-size: 16px
			min-height: 22px
			line-height: 1.2
			opacity: 0.6
	.modal-card
		width: 100%
		max-width: 100%
		padding: 5px
		margin: 0 -5px
		@include touch
			max-height: none
	.modal-card-body
		position: relative
		max-height: calc(100vh - 230px)
		@include touch
			max-height: none
		// margin: 0 0 -15px 0
		margin: 0
	.modal-card-foot
		justify-content: space-between
		z-index: 10
		.button
			padding: 7px 40px
			width: auto
			height: auto
	.modal-score
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		min-width: 100px
		text-transform: uppercase
		user-select: none
		margin: 5px 5px 0
		font:
			size: 45px
			family: $font-medium
		background: $white-ter
		color: $grey-light
		padding: 5px 10px 10px
		line-height: 1
		border-radius: 5px
		letter-spacing: -0.05em
		&.past
			transform: scale(0.8)
		small
			display: block
			letter-spacing: 0
			font:
				size: 14px
				family: $font-light
	.modal-updated
		user-select: none
		display: flex
		justify-content: flex-end
		color: $grey-light
		margin: 0
		font-size: 70%
	&.is-avatar
		.modal-content
			width: 350px

	&.is-sm,
	&.is-users
		.modal-content
			width: 450px

	&.is-md,
	&.is-dealers,
	&.is-categories
		.modal-content
			width: 640px

	&.is-lg,
	&.is-ratings
		.modal-content
			width: 960px

	&.is-delete
		.modal-card
			max-width: 460px

		.modal-card-head
			background: $danger
			text-align: center
			padding: 1.3em

		.modal-card-title
			color: white

		.modal-card-foot
			justify-content: center
			.button
				margin: 0 15px
				border-radius: 2em
				&:focus
					outline: none
					box-shadow: none
				&:not(.is-primary)
					border-color: $danger
					color: $danger
					background: transparent
					&:hover
						background: $danger
						color: $white

		.media-content
			padding: 1em
			text-align: center
			span
				font-size: 1.4rem
				color: $grey-dark
				line-height: 1
				strong
					color: inherit
			small
				display: block
				margin-top: 10px
				&.destroy
					margin: 0
					display: flex
					input
						position: relative
						top: 1px
						margin-right: 5px

	&.is-roles
		.role
			text-transform: uppercase
		.action
			user-select: none
			transition: $transition-base
			cursor: pointer
			&.selected,
			&:hover
				color: $primary
		.item
			margin: 0
			@include touch
				.column
					padding-top: 0
					padding-right: 0
				.is-2-tablet
					padding-left: 0
			&:last-child
				// margin-bottom: $block-spacing * 6
				hr
					display: none

	.modal-content
		@include touch
			width: 90%!important

.modal-rounded
	border-radius: 0 0 5px 5px
.modal-select
	//border-radius: 0 0 5px 5px
	min-height: 250px
	ul
		margin-bottom: 20px
	li
		position: relative
	&__name
		display: block
		margin-bottom: 10px
		color: $grey-dark
		font:
			size: 0.8em
			family: $font-medium
	&__item
		font-family: $font-light
		width: 100%
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 5px
		font-size: 20px
		color: $grey-light
		padding: spacing(2) spacing(1.5)
		border-radius: 5px
		letter-spacing: -0.015em
		line-height: 1
		&.category
			// flex-direction: column
			// align-items: flex-start
			background: lighten($white-ter, 2%)
		.field
			margin: 0px 0 -5px 0
		.control-label
			font-size: 16px
			max-width: 290px
			@extend .is-ellipsis
		.b-checkbox.checkbox input + .check
			box-shadow: none!important
		i
			position: relative
			top: 2px
			color: $grey-light
		&.done
			font-family: $font-regular
			color: $black
			background: transparent
			i
				color: $black
		&.disabled
			opacity: 0.5
			pointer-events: none
			user-select: none
	&__item.checkbox:hover,
	&__item.checked,
	a:hover
		background: $secondary
		color: $white
		.b-checkbox.checkbox input + .check
			border-color: $white
		i,
		.checkbox,
		.control-label
			color: inherit

.modal-groups
	&__item
		position: relative
		display: flex
		justify-content: flex-start
		border: 4px solid $white
		transition: all 0.1s ease-out
		background: $white-bis
		border-radius: 15px
		@include mobile
			flex-wrap: wrap
		@include tablet
			justify-content: space-between
		&.active
			background: $secondary
			color: $white
		&.done
			background: $success
		&.error
			background: $danger
			//background: #ffbbbb
			color: $white
		&.contested
			background: $warning
			color: $white
		.switch
			margin-left: 15px
			margin-right: -5px
		span,
		.field
			display: flex
			align-items: center
		.adjust-width-contest
			margin-right: 10px
			input
				width: 100%
			&.has-equivalence
				input
					padding-right: 50%!important
		.points
			margin: 0 0 0 auto
		&.header
			background: transparent
			border: none
			margin: 0 0 -40px 0
			padding-top: 0
			padding-bottom: 0
			font-size: 12px
			text:
				transform: uppercase
				align: center
	&__group
		position: relative
		color: $grey-dark
		padding-left: 10px
		margin-top: spacing()
		user-select: none
		font:
			size: 16px
			family: $font-medium
		&.first
			margin-top: 0
		.switch
			position: absolute
			top: 2px
			right: 0
			margin: 0 -40px 0 0
			z-index: 15
			input
				box-shadow: none!important
		span
			position: relative
			z-index: 5
			background: $white
			padding-right: spacing()
		&::after
			content: ''
			display: block
			width: 95%
			height: 2px
			background: $white-ter
			position: absolute
			top: 12px
			right: 0
			z-index: 1
	&__name
		width: 100%
		max-width: 100%
		transition: $transition-base
		font:
			size: 0.9em
			family: $font-regular
		// pointer-events: none
		user-select: none
		margin-bottom: 10px
		line-height: 1
		@include tablet
			width: 200px
			margin-bottom: 0
			padding-left: spacing(2)
		@include desktop
			width: 370px
		&.small
			@include desktop
				width: 200px
		&.large
			@include tablet
				width: auto
				font:
					size: 1.3em
		.header &
			font-size: inherit
		span
			max-width: 100%
	&__points
		justify-content: center
		color: $grey-light
		background: $white
		border-radius: 2px
		padding: 5px 15px
		width: 55px
		user-select: none
		pointer-events: none
		text-transform: uppercase
		font:
			size: 20px
			family: $font-medium
		@include tablet
			margin-left: auto
		&.voted
			width: 65px
		.active &
			background: $white
			color: $secondary
		.error &
			background: $white
			color: $danger
		// .contested &
		// 	color: $warning
		.header &
			justify-content: center
			font:
				size: inherit
				family: $font-light
			color: $grey-light
			background: transparent
	&__rate
		width: 130px
		margin: 0 10px
		input
			padding-left: 10px
			padding-right: 10px!important
			text-align: center
			height: 41px
			width: 5rem
			letter-spacing: -0.05em
			text-transform: uppercase
			font-size: 15px!important
		.icon,
		.help
			display: none!important
		.header &
			justify-content: center
	&__notes
		min-width: 250px
		&.comment
			@include mobile
				width: calc(100% - 128px)
				margin-right: 0 !important
		&.contest
			@include mobile
				margin-top: 10px
				width: 100%

		input
			// min-height: 50px
			font-size: 14px
			height: 41px
		.header &
			font-size: inherit
	&__contest
		background: rgba(255, 255, 255, 0.3)
		width: 30px
		height: 23px
		position: relative
		cursor: pointer
		border: 0
		border-radius: 3px 3px 3px 0
		margin: 8px 10px
		margin-right: 0
		color: white
		font:
			size: 14px
			family: $font-regular
		transition: $transition-base
		&:before
			content: ''
			position: absolute
			left: 0
			bottom: -5px
			width: 0
			height: 0
			width: 0
			height: 0
			border-style: solid
			border-width: 5px 5px 0 0
			border-color: rgba(255, 255, 255, 0.3) transparent transparent transparent
			transition: $transition-base
		&:hover,
		&.contested
			background: white
			color: $secondary
			&:before
				border-top-color: white

.modal-payment
	background: $white-ter
	border: 2px solid lighten($grey-light, 20%)
	border-radius: 15px
	padding: 15px 25px 5px
	overflow: hidden
	position: relative
	&--status
		background: #eaffeb
		border-color: #cdfec0
		.select
			&.is-empty
				border: 1px solid white
			select
				height: 2.8em
				min-width: 300px
	&__title
		font:
			size: 20px
			family: $font-medium
		color: $dark
		&--status &
			color: #27ae60
	&__field
		padding-top: 10px
		.input
			max-width: 700px
			border-radius: 30px
		.button
			min-width: 160px
			border-radius: 0 30px 30px 0
			height: 3.1em
		.files
			padding-left: 15px
			user-select: none
			letter-spacing: -0.01em
		.tag
			position: relative
			top: -2px
	&__icon
		fill: $grey-lighter
		position: absolute
		top: -20px
		right: -20px
		width: 200px
		height: auto
		transform: rotate(45deg)
		opacity: 0.1
		pointer-events: none
		&.receipt
			top: -30px
			right: 20px
			transform: rotate(15deg)
			svg
				fill: $success
		svg
			width: 100%

.modal-rules
	background: $white-ter
	border-radius: $radius
	padding: 15px
	display: flex
	flex-wrap: wrap
	align-items: flex-start
	justify-content: space-between
	flex-flow: row wrap
	row-gap: 10px
	// margin-bottom: 150px
	overflow-y: auto
	max-height: 140px
	gap: 12px
	margin: 0 0 10px 0
	&:after
		//content: ''
		//flex: auto
	&__item
		display: flex
		justify-content: flex-start
		align-items: center
		flex: 0 0 calc((100% / 3) - 8px)
		font-size: 16px
		margin: 0
		&.double
			flex: 0 0 48%
		&:last-child
			margin-right: auto
		// .has-icons-right .input
		// 	padding-right: 10px!important
		input
			width: 100%
			padding-right: 10px !important
			appearance: textfield
			text:
				transform: uppercase
				align: center
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button
				appearance: none
		.icon,
		.help
			display: none!important
	&__index
		display: flex
		justify-content: center
		align-items: center
		background: $primary
		color: $white
		font-size: 16px
		width: 40px
		height: 30px
		user-select: none
		border-radius: $radius
		input
			background: transparent
			color: inherit
			border: none
			padding: 0!important
			min-width: 30px
			text-align: center
			border-radius: 4px
			&:focus
				background: $secondary
	&__signal
		padding: 0 10px
		color: $grey-dark
	&__help
		width: 100%
		display: block

.modal-button
	position: fixed
	top: 50%
	left: 30px
	cursor: pointer
	background: transparent
	border: none
	z-index: 999
	padding: 20px
	margin: -40px 0 0 0
	transition: $transition-base
	&:hover
		path
			stroke: $white
	&:disabled 
		opacity: 0.5
		cursor: not-allowed
	&.left	
		transform: rotate(-180deg)
	&.right
		left: auto
		right: 30px

.equivalence
	background: #ececec
	position: absolute
	top: 1px
	right: 1px
	bottom: 1px
	width: 45%
	z-index: 5
	border-radius: 0 5px 5px 0
	justify-content: center
	font-size: 1rem
	user-select: none
	border: 2px solid white
	pointer-events: none
	&.clickable
		pointer-events: all
		cursor: pointer
		transition: $transition-base
		&:hover
			background: $grey-dark
			color: $white
	.points-history
		opacity: 0.4
		text-decoration: line-through
		padding: 0 5px
	.active &
		color: $secondary
	.error &
		color: $danger

.locked-overlay
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	background: rgba(255, 255, 255, 0.5)
	background: repeating-linear-gradient(125deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07) 10px, rgba(0, 0, 0, 0.02) 10px, rgba(0, 0, 0, 0.02) 20px)
	border-radius: 10px
	z-index: 5
	display: flex
	justify-content: center
	align-items: center
	// box-shadow: 0 0 10px rgb(0, 0, 0, 0.1)
	.active &
		background: repeating-linear-gradient(125deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 10px, rgba(255, 255, 255, 0.5) 10px, rgba(255, 255, 255, 0.5) 20px)
	svg
		margin: 0 auto
		padding: 0 10px
		width: 45px
		left: 63px
		// background: $grey-light
		.active &
			background: $white
		.category &
			left: auto
			padding: 0
			margin: 0
			float: right
			right: 15px
			width: 25px
			background: transparent
		.stroke
			stroke: $grey-dark
			.active &
				stroke: $secondary
			.category &
				stroke: $grey-light
		.fill
			fill: $grey-dark
			.active &
				fill: $secondary
			.category &
				fill: $grey-light
