// |------------------------------------------------------
// |------------------------------------------------------
// | Panel
// |------------------------------------------------------
// |------------------------------------------------------

.panel
	padding: 0

	hr
		margin: 0

	&__header
		padding: spacing(1.5) spacing()
		border-bottom: 1px solid $white-ter
		display: flex
		justify-content: space-between
		align-items: center
		min-height: 75px
		&__title
			padding-top: spacing(3)
			color: $primary
			letter-spacing: -0.05em

	&__body
		padding: spacing(1.5) spacing()
